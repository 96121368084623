import React from 'react';
import { Row, Col, Container } from 'react-grid-system';
import styled from 'styled-components';
import { H3 } from '../../../../constants/fonts';
import breakpoints from '../../../../constants/breakpoints';
import color from '../../../../constants/colors';

const Content = styled.div`
  display: none;
  position: relative;
  text-align: left;
  background: ${color.highTransparent};
  padding: 4rem 2rem;
  border-radius: 2rem;
  &:first-of-type {
    margin-bottom: 15vh;
  }
  &::before,
  &::after {
    content: '';
    position: absolute;
    bottom: 1rem;
    width: 40%;
    height: 0.5rem;
    box-shadow: 0 5px 14px rgba(0, 0, 0, 0.5);
    background: transparent;
    opacity: 0.4;
    z-index: -1;
  }
  &::before {
    left: 0.5rem;
    transform: skew(-2deg) rotate(-2deg);
  }
  &::after {
    right: 0.5rem;
    transform: skew(2deg) rotate(2deg);
  }
  @media (min-width: ${breakpoints.sm}) {
    padding: 4rem;
  }
  &.move {
    display: block;
    animation: careerAnimation ease 1s;
    transition: all 1s;

    animation-duration: 1s;
  }
  @keyframes careerAnimation {
    0% {
      opacity: 0;
      transform: translateY(20rem);
    }
    100% {
      opacity: 0.1;
    }
  }
`;

const StyledTab = styled.div`
  display: block;
  background: ${color.lowTransparent};
  margin-bottom: 2rem;
  padding: 2rem 0;
  border-radius: 2rem;
  @media (min-width: ${breakpoints.md}) {
    display: flex;
    justify-content: space-evenly;
  }
`;

const Title = styled(H3)`
  color: ${color.orange};
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  &:first-of-type {
    margin-right: 2rem;
  }
  &.active {
    color: ${color.black};
    text-decoration: underline;
  }
  @media (min-width: ${breakpoints.md}) {
    text-align: unset;
  }
`;

const Tab = ({
  json, titleId, setTitleId, children,
}) => (
  <Container>
    <Row>
      <Col lg={12}>
        <StyledTab>
          {json.map(item => (
            <Title
              key={item.node.item.id}
              onClick={() => setTitleId(item.node.item.id)}
              className={item.node.item.id === titleId ? 'active' : ''}
            >
              {item.node.item.title}
            </Title>
          ))}
        </StyledTab>
        {json.map(item => (
          <Content key={item.node.item.id} className={item.node.item.id === titleId ? 'move' : ''}>
            {children}
          </Content>
        ))}
      </Col>
    </Row>
  </Container>
);
export default Tab;

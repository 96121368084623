import React, { useState } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '../../components/SEO';
import Layout from '../../components/Layout';
import { careerBg } from '../../assets/images';
import { logo, tick, circle } from '../../assets/icons';
import { H4 } from '../../constants/fonts';
import breakpoints from '../../constants/breakpoints';
import color from '../../constants/colors';
import Tab from '../../components/common/components/Tab';
import Link from '../../components/common/components/Link';

const Wrapper = styled.div`
  position: relative;
  padding: 5vh 0;
  &::before {
    background-image: url(${careerBg});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: top;
    position: absolute;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    z-index: -1;
  }
  @media (min-width: ${breakpoints.sm}) {
    padding: 15vh 0;
  }
  @media (min-width: ${breakpoints.md}) {
    background-size: 40%;
  }
`;

const CareerText = styled.div`
  &::before {
    background-image: url(${logo});
    background-repeat: no-repeat;
    background-size: 20%;
    background-position: center;
    position: absolute;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.9;
    z-index: -1;
  }
`;

const Subtitle = styled(H4)`
  color: ${color.grey};
`;

const CareerOfferUl = styled.ul`
  padding-left: 0;
  & > li {
    padding: 0.5rem 0;
    list-style: none;
  }
  & > li::before {
    background-image: url(${circle});
    content: '';
    display: inline-block;
    height: 1.2rem;
    width: 1.2rem;
    background-size: 30%;
    background-repeat: no-repeat;
    background-position: bottom;
    margin-right: 1.8rem;
  }
  @media (min-width: ${breakpoints.sm}) {
    padding-left: 2rem;
  }
`;

const CareerExpectationsUl = styled.ul`
  padding-left: 0;
  & > li {
    padding: 0.5rem 0;
    list-style: none;
  }
  & > li::before {
    background-image: url(${tick});
    content: '';
    display: inline-block;
    height: 1.2rem;
    width: 1.2rem;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom;
    margin-right: 1.8rem;
  }
  @media (min-width: ${breakpoints.sm}) {
    padding-left: 2rem;
  }
`;

const ContactList = styled.ul`
  padding-left: 0;
`;

const Career = ({ data }) => {
  const json = data.allCareerJson.edges;

  const [titleId, setTitleId] = useState(1);

  return (
    <Layout>
      <SEO title="Kariera" />
      <Wrapper>
        <Tab json={json} setTitleId={setTitleId} titleId={titleId}>
          <CareerText>
            {json.map(
              edge => edge.node.item.id === titleId && (
                  <div key={edge.id}>
                    <Subtitle>Oferujemy:</Subtitle>
                    <CareerOfferUl>
                      {edge.node.item.offer.map(item => (
                        <li key={item.id}>{item.list ? item.list : null}</li>
                      ))}
                    </CareerOfferUl>
                    <Subtitle>Oczekujemy:</Subtitle>
                    <CareerExpectationsUl>
                      {edge.node.item.expectations.map(item => (
                        <li key={item.id}>{item.list ? item.list : null}</li>
                      ))}
                    </CareerExpectationsUl>
                    <Link to="/contact" text="Skontaktuj się z nami!" />
                    <ContactList>
                      {edge.node.item.contact.phones.map(item => (
                        <li key={item.id}>
                          <a href={`tel:${item.list ? item.list : null}`}>
                            {item.list ? item.list : null}
                          </a>
                        </li>
                      ))}
                      <li>
                        <a href={`mailto:${edge.node.item.contact.email}`}>
                          {edge.node.item.contact.email}
                        </a>
                      </li>
                    </ContactList>
                  </div>
              ),
            )}
          </CareerText>
        </Tab>
      </Wrapper>
    </Layout>
  );
};

export const query = graphql`
  query {
    allCareerJson {
      edges {
        node {
          id
          intro {
            list
          }
          item {
            title
            id
            offer {
              list
            }
            expectations {
              list
            }
            contact {
              phones {
                list
              }
              email
            }
          }
        }
      }
    }
  }
`;

export default Career;
